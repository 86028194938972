@import "~vis/dist/vis.css";
@import "~rc-slider/assets/index.css";

#content {
  margin-bottom: 4em;
}

.ui.dividing.header {
  margin-top: 1em;
  margin-bottom: 2em;
}

div.ui.grey.circular.label {
  color: #c5c5c5;
}

i.icon {
  margin: 0;
}

.ui.grid {
  margin-top:8px!important;
}

#cvegraph {
    background-color: #ffffff
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 2em;
  padding: 10px 10px 10px 40px;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 90%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

#loadingBar {
  position:absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color:rgba(200,200,200,0.8);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity:1;
}

#text {
  position:absolute;
  top:4px;
  left:530px;
  width:30px;
  height:50px;
  margin:auto auto auto auto;
  font-size:18px;
  color: #000000;
}


div.outerBorder {
  position:relative;
  top:100px;
  width:600px;
  height:44px;
  margin:auto auto auto auto;
  border:8px solid rgba(0,0,0,0.1);
  background: rgb(252,252,252); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(252,252,252,1) 0%, rgba(237,237,237,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(252,252,252,1)), color-stop(100%,rgba(237,237,237,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(252,252,252,1) 0%,rgba(237,237,237,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(252,252,252,1) 0%,rgba(237,237,237,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(252,252,252,1) 0%,rgba(237,237,237,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(252,252,252,1) 0%,rgba(237,237,237,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#ededed',GradientType=0 ); /* IE6-9 */
  border-radius:72px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

#border {
  position:absolute;
  top:3px;
  left:10px;
  width:500px;
  height:23px;
  margin:auto auto auto auto;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
  border-radius:10px;
}

#bar {
  position:absolute;
  top:0px;
  left:0px;
  width:20px;
  height:20px;
  margin:auto auto auto auto;
  border-radius:11px;
  border:2px solid rgba(30,30,30,0.05);
  background: rgb(0, 173, 246); /* Old browsers */
  box-shadow: 2px 0px 4px rgba(0,0,0,0.4);
}

.tipselector1 {}

.tipselector2 {}

.tipselector3 {}

.tipselector4 {}

.tipselector5 {}
